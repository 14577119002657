import React, {useMemo, useState, useEffect} from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MaterialButton from '@mui/material/Button';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import { DataGrid } from '@mui/x-data-grid';

import { Auth } from "aws-amplify";

import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Flex,
  Text,
  SwitchField
} from "@aws-amplify/ui-react";


import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';

import {post, getUsers, postEmployee} from "../services/UserService";

const columns = [
  { field: 'Username', headerName: 'Brukernavn', width: 200 },
  {
    field: 'Name',
    headerName: 'Navn',
    width: 200,
    editable: false,
  },
  {
    field: 'LastName',
    headerName: 'Etternavn',
    width: 200,
    editable: false,
  },

  {
    field: 'Email',
    headerName: 'Email',
    width: 200,
    editable: false,
  },

  {
    field: 'Locale',
    headerName: 'Gruppetilgang',
    width: 200,
    editable: false,
  },

  /* {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.status)}`,
  }, */

];


export default function Employees(){

    const [open, setOpen] = React.useState(false);
    const [pNR, setpNr] = useState(100)
    const [name, setName] = useState("")
    const [lastname, setLastName] = useState("")

    const [email, setEmail] = useState("")
  
  
    const [userData, setUserData] = useState([])
    const [shownData, setShownData] = useState([])
    const [isChecked, setIsChecked] = useState(true);
    const [arrivalDate, setArrivalDate] = useState(new Date());
    const [group, setGroup] = React.useState('');
    const [dialogTitle, setDialogTitle] = React.useState('');
    const [isNew, setIsNew] = useState(true)
    const [userInst, setUserInst] = useState('')
  
  const [photo, setPhoto] = useState()
   const { user} = useState()


   useEffect(() => {

    getInst()

    
}, []); 

const getUserData = async (inst) => {

  let instInfo = {
    zoneinfo: inst
  }

  const requestOptions = {
    method: 'POST',
    headers: { 
        'Content-Type': 'application/json',
        'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ', 
    }, 
    body: JSON.stringify(instInfo)
};
fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getAllFromUserPool', requestOptions)
.then(response => response.json())
.then(function(data) {
  console.log("data " + data[0].Attributes[0].Value)
setUserData(data)

const mappedUserData = data.map(user => {
  const emailAttribute = user.Attributes.find(attribute => attribute.Name === "email");
  const email = emailAttribute ? emailAttribute.Value : 'N/A'; // Handle the case where email is not found
  const localeAttribute = user.Attributes.find(attribute => attribute.Name === "locale");
  const locale = localeAttribute ? localeAttribute.Value : 'Ikke oppgitt'; // Handle the case where email is not found
  const nameAttribute = user.Attributes.find(attribute => attribute.Name === "name");
  const name = nameAttribute ? nameAttribute.Value : 'Ikke oppgitt'; // Handle the case where email is not found
  const lastNameAttribute = user.Attributes.find(attribute => attribute.Name === "family_name");
  const lastName = lastNameAttribute ? lastNameAttribute .Value : 'Ikke oppgitt'; // Handle the case where email is not found
  return {
      ...user,
      Email: email,
      Locale: locale, 
      Name: name, 
      LastName: lastName,
  };
});



setShownData(mappedUserData)
},);
}

      const getInst = async () => {

        let user = await Auth.currentAuthenticatedUser();
        
        setUserInst(user.attributes.zoneinfo)
        getUserData(user.attributes.zoneinfo)
       
        }

        const handleRowClick = (e) => {
          setDialogTitle("Rediger ansatt")
         
          setOpen(true);
          setName(e.row.Name)
          setLastName(e.row.LastName)
          setEmail(e.row.Email)
          setGroup(e.row.Locale)
      
         setIsNew(false)
         }

         function ShowGroup(){
          switch(userInst){
            case '01': 
            return (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">  
              <InputLabel id="demo-simple-select-label">Gruppe</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={group}
                label="Gruppe"
                onChange={selectHandleChange}
              >
                <MenuItem value={"2C"}>2C</MenuItem>
                <MenuItem value={"2D"}>2D</MenuItem>
                <MenuItem value={"2E"}>2E</MenuItem>
                <MenuItem value={"1B"}>1B</MenuItem>
                <MenuItem value={"ALL"}>Alle</MenuItem>
                <MenuItem value={"TEST"}>TEST</MenuItem>
              </Select>
            </FormControl>
            )
            break;
            case '02': 
            return null 
            break;
          }
        } 

        const selectHandleChange = (event) => {
          setGroup(event.target.value);
        };
    
      const pNRHandleChange = (event) => {
       setEmail(event.target.value);
      };

      const nameHandleChange = (event) => {
      setName(event.target.value);
      };


      const lastnameHandleChange = (event) => {
        setLastName(event.target.value);
        };

      const handleClickOpen = () => {

        if(!isNew){
          setName("")
          setEmail("")
         setIsNew(true)
        } else {
          setName("")
          setpNr(100)
        }
        
            setDialogTitle("Ny ansatt")
            setOpen(true);
          };
        
          const handleClose = () => {
            setOpen(false);
        
          };
        
          const handleSave = () => {
            setOpen(false);  
            
          let user_object= {
              email: email,
              name: name, 
              locale: group,
              lastname: lastname,
              zoneinfo: userInst, 

        }
        
        console.log("DAte " + user_object.email)
        
        postEmployee(user_object)
        }


        const handleDelete = () => {
          setOpen(false);  
          
        let user_object= {
            email: email,
            name: name, 
            locale: group,
            lastname: lastname,
            zoneinfo: userInst, 

      }
      postEmployee(user_object, 'DELETE')
      }
    
      


    return (
        <Box>
              <Box
                sx={{ flexDirection : 'column', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Typography variant="h3" 
                sx={{ paddingTop: 6}}>
                  Ansatte</Typography>
              </Box>
              <Box   
       sx={{ width: '20%', mx: 3, top: '18%', right: '2%', position: 'absolute', }}>
        <MaterialButton 
        color='custom' 
      variant="contained" onClick={handleClickOpen}>
        Opprett ny ansatt
      </MaterialButton>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Legg inn nødvendige ansattopplysninger. Alle felt er obligatoriske.
          </DialogContentText>
          <TextField
          sx={{ my: 2 }}
            autoFocus
            margin="dense"
            id="name"
            label="Fornavn"
            fullWidth
            type="text"
            variant="standard"
            value={name}
            onChange={nameHandleChange}
            required
          />
          <TextField
          sx={{ my: 2 }}
            autoFocus
            margin="dense"
            id="lastname"
            label="Etternavn"
            fullWidth
            type="text"
            variant="standard"
            value={lastname}
            onChange={lastnameHandleChange}
            required
          />
          <TextField
          sx={{ my: 2 }}
            autoFocus
            margin="dense"
            id="email"
            label="Epost"
            fullWidth
            type="email"
            variant="standard"
            value={email}
            onChange={pNRHandleChange}
            required
          />
          <ShowGroup />
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">  
 
</FormControl>
        </DialogContent>
        <DialogActions>
          <MaterialButton color="error" disabled={isNew} onClick={handleDelete} sx={{ alignSelf: 'center'}} >Slett</MaterialButton>
          <MaterialButton onClick={handleSave}>Lagre</MaterialButton>
          <MaterialButton onClick={handleClose}>Avbryt</MaterialButton>
          
        </DialogActions>
      </Dialog>



      <Box sx={{ height: 630, width: '100%', paddingTop: 6 }}>
      <DataGrid
        getRowId={(row) => row.Username}
        onRowClick={handleRowClick}
        rows={shownData}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
          </Box>
    )

}


export const toText = (value) => {
  switch(value){
      case 1: 
      return 'Aktiv'
      case 2: 
      return 'Aldri innlogget'
      case 3:
      return 'Nytt passord'
      case 4: 
      return 'Deaktivert'   
      default: 
      return '' 
  }
}