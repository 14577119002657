import React from "react";
import {useNavigate} from "react-router-dom";

import Adfectus_logo from "../assets/Adfectus_logo.png";

import "./Login.css";

function Login() {

    let navigate = useNavigate();

    return (

    <div className="MainLogin">

        <img src={Adfectus_logo} className="Logo" alt="Logo" />

        <div className="LoginField">
          <label className="LoginLabel"> Brukernavnet ditt : </label>
          <input type="text" id="username" name="username" placeholder="Brukernavn" className="LoginInput" />

          <label className="LoginLabel"> Passord </label>
          <input type="password" id="password" name="password" placeholder="Passord" className="LoginInput" />
          
        </div>
        
        <input type="submit" value="Logg Inn" className = "LoginButton" onClick={() => navigate('/mainmeny')} />    

    </div>

    );
}
export default Login;