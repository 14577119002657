import React, {useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import { useTable, useSortBy } from "react-table";

import Sidebar from "./Sidebar";

import MOCK_DATA from '../assets/MOCK_DATA.json';
import {COLUMNS} from './GroupinfoColumns';

import {AiFillCaretDown, AiFillCaretUp} from "react-icons/ai";

import "./Groupinfo.css";

function Groupinfo() {

    let navigate = useNavigate();

    const { group } = useParams();

    const groupnr = group.substring(0,4)

    let week = 0;
    let year = 0;

    if (group.length == 10) {
        week = group.substring(4, 6)
        year = group.substring(6, group.length)
    } else {
        week = group.substring(4, 5)
        year = group.substring(5, group.length)
    }

    const groupmembers = []

    for (let i = 0; i < MOCK_DATA.length; i++) {
        if (groupnr == MOCK_DATA[i].group){
            groupmembers.push(MOCK_DATA[i])
        }
    }

    const columns = useMemo(() => COLUMNS, [])

    const data = useMemo(() => groupmembers, [])

    const tableInstance = useTable({columns, data})

    const {
        getTableProps, 
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
    useSortBy
    )

    return (

    <div className="Main">
        
        <Sidebar/>

        {console.log('test: ', groupmembers)}

        <h1>Gruppe {groupnr} <br/> <span className="WeekYear">Uke {week} - År {year} </span></h1>

        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                            {column.render('Header')}
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? <AiFillCaretDown/> : <AiFillCaretUp/>): ' '}
                            </span>
                        </th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()} onClick={() => navigate(`/userinfo/${cell.row.values.user_id}`)}>{cell.render('Cell')}</td>
                                
                            })}
                        </tr>
                    )
                    })}  
            </tbody>
            <tfoot>
                {
                    footerGroups.map(footerGroup => (
                        <tr {...footerGroup.getFooterGroupProps()}>
                            {footerGroup.headers.map(column => (
                                <td {...column.getFooterProps}>{column.render('Footer')}</td>
                                ))}
                        </tr>
                    ))
                }
            </tfoot>   
        </table>
    

    </div>

    );
}
export default Groupinfo;