export const COLUMNS = [
    {
        Header: 'Gruppe',
        Footer: 'Gruppe',
        accessor: 'group'
    },    
    {
        Header: 'Uke',
        Footer: 'Uke',
        accessor: 'week'
    },
    {
        Header: 'År',
        Footer: 'År',
        accessor: 'year'
    }
]
