import Userinfo from "./Userinfo"

export const COLUMNS = [
    {
        Header: 'Pasientnummer',
        Footer: 'Pasientnummer',
        accessor: 'userId',
    }, 
    {
        Header: 'Navn',
        Footer: 'Navn',
        accessor: 'firstName',
    }, 
    {
        Header: 'Gruppe',
        Footer: 'Gruppe',
        accessor: 'groupId'
    },
    {
        Header: 'Ankomstdato',
        Footer: 'Ankomstdato',
        accessor: row => formatDate(row.arrivalDate),
        id: 'arrivalDate'
    },
   
    {
        Header: 'Basseng',
        Footer: 'Basseng',
        accessor: row => toText(row.pool),
        id: 'pool'
    },
    {
        Header: 'Aktivitet gymsal',
        Footer: 'Aktivitet gymsal',
        accessor: row => toText(row.gym),
        id: 'gym'
    },
    {
        Header: 'Gå tur',
        Footer: 'Gå tur',
        accessor: row => toText(row.hike),
        id: 'hike'
    },
    {
        Header: 'Hest',
        Footer: 'Hest',
        accessor: row => toText(row.horse),
        id: 'horse', 
        minWidth: 500, 
        maxWidth: 500
    },
    {
        Header: 'Utendørs vann',
        Footer: 'Utendørs vann',
        accessor: row => toText(row.canoe),
        id: 'canoe'
    },

    {
        Header: 'Klatring',
        Footer: 'Klatring',
        accessor: row => toText(row.climbing),
        id: 'climbing'
    },
    {
        Header: 'Sykling',
        Footer: 'Sykling',
        accessor: row => toText(row.cycle),
        id : 'cycle'
    },
    {
        Header: 'Friluftsliv',
        Footer: 'Friluftsliv',
        accessor: row => toText(row.outdoor),
        id: 'outdoor'
    },
    {
        Header: 'Alpin',
        Footer: 'Alpin',
        accessor: row => toText(row.alpin),
        id: 'alpin'
    },
    {
        Header: 'Skøyter',
        Footer: 'Skøyter',
        accessor: row => toText(row.skates),
        id: 'skates'
    },
    {
        Header: 'Langrenn',
        Footer: 'Langrenn',
        accessor: row => toText(row.skiing),
        id: 'skiing'
    },
 
    {
        Header: 'Aktivitet skjerm',
        Footer: 'Aktivitet skjerm',
        accessor: row => toText(row.screen),
        id: 'screen'
    },
    {
        Header: 'Leke ute',
        Footer: 'Leke ute',
        accessor: row => toText(row.playOutside),
        id: 'playOutside'
    },

    {
        Header: 'Bevegelse musikk',
        Footer: 'Bevegelse musikk',
        accessor: row => toText(row.musicMove),
        id: 'musicMove'
    }
]


const formatDate = (date) => {


    var newDate = new Date(date)
    
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    let format = day + "." + month + "." + year
    
    return format;
    
    }


export const toText = (value) => {
    switch(value){
        case 1: 
        return 'Ikke gøy'
        case 2: 
        return 'Skummelt'
        case 3:
        return 'Litt gøy'
        case 4: 
        return 'Veldig gøy'   
        case 5: 
        return 'Vet ikke'    
    }
}


export const isPhoto = (value) => {
    if(value){
        return 'Generert'
    } else {
        return 'Ikke generet'
    }
}