import React, {useMemo, useState, useEffect}  from "react";
import {useNavigate, useLocation} from "react-router-dom";
import { sort } from '../services/SortingService'
import { useTable, useSortBy, useGlobalFilter } from "react-table";

import Box from '@mui/material/Box';


import Typography from '@mui/material/Typography';

import Sidebar from "./Sidebar";
import ColumnChart from './Chart'

import { Auth } from 'aws-amplify';

import MOCK_DATA from '../assets/MOCK_DATA.json';
import { getUsers } from '../services/UserService'

import {COLUMNS} from './UserColumns';
import AddUserPopUp from "./AddUserPopUp";
import { GlobalFilter } from "./GlobalFilter";


import MaterialButton from '@mui/material/Button';

import {AiFillCaretDown, AiFillCaretUp} from "react-icons/ai";

import './Users.css';


function Users() {



    let navigate = useNavigate();
    let location = useLocation();

    const columns = useMemo(() =>
     COLUMNS, [])
  

     const [shownData, setShownData] = useState([])

    const [data, setData] = useState([])
    const [group, setGroup] = useState([])
    const [sortedData, setSortedData] = useState([])
    const [userData, setUserData] = useState([])

      const setUsers = async(groupNr) => {
       
        let groupInfo = {
            groupId: groupNr
        }
      
        console.log("Body " + JSON.stringify(groupInfo ))
    
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'x-api-key': 'XB0AKjyrCloFsXp4AJ0e8h0VK2VJkhB7t2430jia', 
            }, 
            body: JSON.stringify(groupInfo)
        };
       fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getusersbygroup', requestOptions)
        .then(response => response.json())
       .then(function(data) {
    
        console.log("data " + data)
           
        setData(data.Items)
        setShownData(data.Items)
        
      } ,);
      }


    const getUserData = async () => {

        let groupNr = 0; 

       let user = await Auth.currentAuthenticatedUser();
       groupNr = user.attributes.locale;
            setUserData(groupNr)
            setUsers(groupNr)
        };

   useEffect(() => {
   getUserData();
}, []);

const initialState = {hiddenColumns: ['photoString']}
    const {
        getTableProps, 
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
    } = useTable({
        columns,
        data, 
        initialState
    }, 
    useGlobalFilter,
    useSortBy
    )
    

    const {globalFilter} = state

    const [isOpen, setIsOpen] = useState(false);

    let currentDate = new Date();

    const formatDate = (date) => {


var newDate = new Date(date)

let day = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

let format = day + "-" + month + "-" + year

return format;

}
      

    const filterGroup = () => {
        //setUsers(group)
        console.log("called")

     }

    const filterFutureUsers = () => {

        setData(shownData.filter(value => new Date(value.arrivalDate).getTime() > new Date(currentDate).getTime()))
      }
     
      const filterCurrentUsers = () => {
     

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

       let firstSort = shownData.filter(value => new Date(value.arrivalDate).getTime() <= new Date(currentDate).getTime())
     
     
      setData(firstSort.filter(value => new Date(value.arrivalDate).addDays(20).getTime() > new Date(currentDate).getTime()))
      }
     
     const filterPreviousUsers = () => {

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
          }


       setData(shownData.filter(value => new Date(value.arrivalDate).addDays(20).getTime() < new Date(currentDate).getTime()))
     }
     
     
      const allUsers = () => {
       setData(shownData)
     }


    return (
    
    <div className="Users">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start'}}>

<Typography variant="h4">Brukere</Typography>
       

        <AddUserPopUp open={isOpen} onClose={() => setIsOpen(false)}/>

    {/*   <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} className="SearchGlobalFilter"/> */}
{userData == 1 &&
    <div>
                    <MaterialButton  onClick={() => setUsers("2C")}>2C</MaterialButton>
                   <MaterialButton  onClick={() => setUsers("2D")}>2D</MaterialButton >
                    <MaterialButton  onClick={() => setUsers("2E")}>2E</MaterialButton>
                    <MaterialButton  onClick={() => setUsers("1B")}>1B</MaterialButton>
                    <MaterialButton  onClick={() => setUsers("TEST")}>TEST</MaterialButton>
             </div>

}
     {/*    <button onClick={() => navigate('/newuser')}>Opprett ny</button>  */}
     <Box>
     <MaterialButton onClick={allUsers}>Alle brukere </MaterialButton>
     <MaterialButton  onClick={filterPreviousUsers}>Tidligere</MaterialButton>
    <MaterialButton  onClick={filterCurrentUsers}>Nåværende</MaterialButton >
     <MaterialButton  onClick={filterFutureUsers}>Kommende</MaterialButton>
     </Box>

        <div class="break"></div>
        
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                            {column.render('Header')}
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? <AiFillCaretDown/> : <AiFillCaretUp/>): ' '}
                            </span>
                        </th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()} onClick={() => navigate(`/userinfo/${cell.row.values.userId}`, 
                                { state: {week: cell.row.values.weekNumber, name: cell.row.values.firstName, photo: cell.row.values.photoString, group: cell.row.values.groupId, gym: cell.row.values.gym, horse: cell.row.values.horse, musicMove: cell.row.values.musicMove,
                                    pool: cell.row.values.pool, hike: cell.row.values.hike, canoe: cell.row.values.canoe, climbing: cell.row.values.climbing, 
                                    cycle: cell.row.values.cycle, outdoor: cell.row.values.outdoor, screen: cell.row.values.screen, playOutside: cell.row.values.playOutside, alpin: cell.row.values.alpin, skates: cell.row.values.skates, skiing: cell.row.values.skiing  } })}>{cell.render('Cell')}</td>
                            })}
                          
                        </tr>
                    )
                    })}  
            </tbody>
         {/*    <tfoot>
                {
                    footerGroups.map(footerGroup => (
                        <tr {...footerGroup.getFooterGroupProps()}>
                            {footerGroup.headers.map(column => (
                                <td {...column.getFooterProps}>{column.render('Footer')}</td>
                                ))}
                        </tr>
                    ))
                }
            </tfoot>    */}
        </table>
        <div class="breakTable"></div>
        
   {/*  <ColumnChart class="chart" data={sort(shownData)}/> */}
   </Box>
    </div>
    );
}
export default Users;