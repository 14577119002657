import React, {useMemo, useState, useEffect} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";

import MOCK_DATA from '../assets/MOCK_DATA.json';

import Sidebar from "./Sidebar";
import AvatarImage from '../assets/boy_wheelchair-8.png'
import UserTable from "./UserTable";
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";

import MaterialButton from '@mui/material/Button';
import { object } from "prop-types";

function Userinfo(props) {

    const [photo, setPhoto] = useState()
    const { user} = useParams();
    const { state } = useLocation();
    const { group, gym, cycle, horse,
        pool, climbing, canoe, outdoor, 
        playOutside, hike, screen, musicMove, name, week} = state; 



const getPhoto = async () => {
    let userInfo = {
        userId: user
    }
  
    console.log("Body " + JSON.stringify(userInfo))

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'x-api-key': 'XB0AKjyrCloFsXp4AJ0e8h0VK2VJkhB7t2430jia', 
        }, 
        body: JSON.stringify(userInfo)
    };

const response = await fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getdoorsign', requestOptions)
   if(response.ok){
const json = await response.json()
if(json.startsWith('https'))
{
  window.open(json, '_blank', 'noreferrer')
}
  } else {
      setPhoto(1)

  }
}


function ShowPhoto(){
    if (photo === 1){
        return (
            <Box sx={{width: '100%'}}>
           <Typography>Ikke generert dørskilt</Typography>
        </Box>
        )
    } else {
        return null 
    }
} 

    return (
        <div className="UserInfo">

        <Typography variant="h4">Pasientnummer: {user}</Typography>
        <Typography variant="h5">Gruppe: {group}</Typography>
        <Typography variant="h5">Navn: {name}</Typography>
    <Box style={{marginTop: '4%'}} sx={{ flexGrow: 1 }}>
       <MaterialButton onClick={getPhoto}>Hent dørskilt</MaterialButton>
     <ShowPhoto/>
    {/* <Box sx={{width: '100%'}}>
            <img src={`data:image/png;base64,${data}`} width='60%' />

        </Box> */}
        <Grid container spacing={2}>
        <Grid item xs={3}>
            
      
        </Grid>
        <Grid>
   <UserTable />
   </Grid>
   </Grid>
   </Box>
    </div>
    

    );
}
export default Userinfo;