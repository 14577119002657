import React, {useMemo, useState, useEffect}  from "react";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGrid } from '@mui/x-data-grid';

import { Auth } from 'aws-amplify';
import UserInfoDrawer from "./UserInfoDrawer";

const columns = [
  { field: 'userId', headerName: 'PasientID', width: 100 },
  {
    field: 'firstName',
    headerName: 'Fornavn',
    width: 120,
    editable: false,
  },

  {
    field: 'arrivalDate',
    headerName: 'Ankomst',
    type: 'dateTime',
    width: 90,
    editable: false,
    valueFormatter: (params) => {
        if (params.value == null){
            return '';
        }
        const valueFormatted = formatDate(params.value)
        return `${valueFormatted}`;
    }
  },

  {
    field: 'horse',
    headerName: 'Ri',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.horse)}`,
  },

  {
    field: 'climbing',
    headerName: 'Klatring',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.climbing)}`,
  },

  {
    field: 'pool',
    headerName: 'Basseng',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.pool)}`,
  },
  {
    field: 'gym',
    headerName: 'Aktivitet gymsal',
    width: 120,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.gym)}`,
  },

  {
    field: 'cycle',
    headerName: 'Sykling',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.cycle)}`,
  },

  {
    field: 'hike',
    headerName: 'Være på tur',
    width: 110,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.hike)}`,
  },

  {
    field: 'outdoor',
    headerName: 'Friluftsliv',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.outdoor)}`,
  },

  {
    field: 'alpin',
    headerName: 'Alpin',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.alpin)}`,
  },
  {
    field: 'skates',
    headerName: 'Skøyter',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.skates)}`,
  },
  {
  field: 'skiing',
  headerName: 'Langrenn',
  width: 90,
  editable: false,
  valueGetter: (params) => 
  `${toText(params.row.skiing)}`,
},
{
    field: 'canoe',
    headerName: 'Utendørs vann',
    width: 110,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.canoe)}`,
  },
  {
    field: 'screen',
    headerName: 'Aktivitet skjerm',
    width: 110,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.screen)}`,
  },
  {
    field: 'playOutside',
    headerName: 'Leke ute',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.playOutside)}`,
  },
  {
    field: 'musicMove',
    headerName: 'Bevegelse musikk',
    width: 130,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.musicMove)}`,
  },

];

const columnsRKHR = [
  { field: 'userId', headerName: 'PasientID', width: 140 },
  {
    field: 'firstName',
    headerName: 'Fornavn',
    width: 120,
    editable: false,
  },

  {
    field: 'arrivalDate',
    headerName: 'Ankomst',
    type: 'dateTime',
    width: 90,
    editable: false,
    valueFormatter: (params) => {
        if (params.value == null){
            return '';
        }
        const valueFormatted = formatDate(params.value)
        return `${valueFormatted}`;
    }
  },

  {
    field: 'rkhr_bowarrow',
    headerName: 'Pil og bue',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_bowarrow)}`,
  },

  {
    field: 'rkhr_climbing',
    headerName: 'Klatring',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_climbing)}`,
  },

  {
    field: 'rkhr_cooking',
    headerName: 'Matlaging',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_cooking)}`,
  },
  {
    field: 'rkhr_cycling',
    headerName: 'Sykling',
    width: 120,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_cycling)}`,
  },

  {
    field: 'rkhr_gym',
    headerName: 'Gym',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_gym)}`,
  },

  {
    field: 'rkhr_handicrafts',
    headerName: 'Håndarbeid',
    width: 110,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_handicrafts)}`,
  },

  {
    field: 'rkhr_outdoor',
    headerName: 'Friluftsliv',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_outdoor)}`,
  },

  {
    field: 'rkhr_pool',
    headerName: 'Basseng',
    width: 90,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_pool)}`,
  },
  {
    field: 'rkhr_sea',
    headerName: 'Aktivitet sjø',
    width: 100,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_sea)}`,
  },
  {
  field: 'rkhr_shooting',
  headerName: 'Skyting',
  width: 90,
  editable: false,
  valueGetter: (params) => 
  `${toText(params.row.rkhr_shooting)}`,
},
{
    field: 'rkhr_tabletennis',
    headerName: 'Bordtennis',
    width: 110,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_tabletennis)}`,
  },
  {
    field: 'rkhr_vr',
    headerName: 'VR',
    width: 110,
    editable: false,
    valueGetter: (params) => 
    `${toText(params.row.rkhr_vr)}`,
  }

];


export default function UsersDataGrid({groupNr}) {

    const [shownData, setShownData] = useState([])
    const [data, setData] = useState([])
    const [user, setUser] = useState([])
    const [value, setValue] = useState(0);
    const [drawer, setDrawer] = useState(false)

    const [currentDate, setCurrentDate] = useState(new Date())
    const [ userData, setUserData ] = useState()

    const [instColums, setInstColumns] = useState(columns)
    const [instID, setInstID] = useState('01')


    const handleChange = (event, newValue) => {
        setValue(newValue);
        
        switch(newValue){
            case 0: filterPreviousUsers()
            break; 
            case 1: filterCurrentUsers()
            break; 
            case 2: filterFutureUsers()

        }
      };

        const getInst = async () => {

        let user = await Auth.currentAuthenticatedUser();

        let inst = user.attributes.zoneinfo
        
        if(inst === '01'){
          setInstColumns(columns)
        } else if(inst === '02'){
          setInstColumns(columnsRKHR)
        }

        setInstID(inst)
       
        }
    

    const setUsers = async() => {
       
        let groupInfo = {
            groupId: groupNr
        }
      
        console.log("Body " + JSON.stringify(groupInfo ))
    
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'x-api-key': 'XB0AKjyrCloFsXp4AJ0e8h0VK2VJkhB7t2430jia', 
            }, 
            body: JSON.stringify(groupInfo)
        };
       fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getusersbygroup', requestOptions)
        .then(response => response.json())
       .then(function(data) {
    
        console.log("data " + data.Items[0])
           
        setData(data.Items)
        setShownData(data.Items)

      } ,);
      }


   useEffect(() => {
     getInst();
    setUsers(groupNr);


}, []);

const filterFutureUsers = () => {
    console.log("called")

    setData(shownData.filter(value => new Date(value.arrivalDate).getTime() > new Date(currentDate).getTime()))
  }
 
  const filterCurrentUsers = () => {
 

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

   let firstSort = shownData.filter(value => new Date(value.arrivalDate).getTime() <= new Date(currentDate).getTime())
 
 
  setData(firstSort.filter(value => new Date(value.arrivalDate).addDays(20).getTime() > new Date(currentDate).getTime()))
  }
 

const filterPreviousUsers = () => {

    Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }  
   setData(shownData.filter(value => new Date(value.arrivalDate).addDays(20).getTime() < new Date(currentDate).getTime()))
 }

function ShowDrawer(userInfo){

  if(drawer){
    console.log("Userinfo " + user.firstName)
    return (
      <UserInfoDrawer open={drawer} user={user} instID={instID}/>
    )
   
  } else {
    return (
      null
    )
  }
}

 const handleRowClick = (event) => {
     setDrawer(true)
     setUser(event.row)
     console.log("Pushed row " +  user + " " + event.id + " " + event.row.firstName + " " + JSON.stringify(event.data)) 
 }

  return (
      <Box sx={{flexDirection: 'column', width:'100%', display: 'flex', alignItems: 'flex-start'}}>
    <Box sx={{ borderBottom: 2, borderColor: 'divider', 
     bgcolor: 'background.paper', width: '100%', height: 65, 
     display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', paddingRight: '2%'}}>
           <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Tidligere" />
        <Tab label="Nåværende" />
        <Tab label="Kommende" />
      </Tabs>
     </Box>
    <Box sx={{ height: 630, width: '100%', paddingTop: 6 }}>
      <DataGrid
        getRowId={(row) => row.userId}
        onRowClick={handleRowClick}
        rows={data}
        columns={instColums}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
   <ShowDrawer 
   user= {user}/>
    </Box>
    </Box>
  );
}


const formatDate = (date) => {


    var newDate = new Date(date)
    
    let day = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    let format = day + "." + month + "." + year
    
    return format;
    
    }

    export const toText = (value) => {
        switch(value){
            case 1: 
            return 'Ikke gøy'
            case 2: 
            return 'Skummelt'
            case 3:
            return 'Litt gøy'
            case 4: 
            return 'Veldig gøy'   
            case 5: 
            return 'Vet ikke'   
            default: 
            return '' 
        }
    }

  