import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Auth } from 'aws-amplify';

import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Flex,
  Text,
  SwitchField
} from "@aws-amplify/ui-react";

import GroupIcon from '@mui/icons-material/Group';
import logo from '../assets/simplyLogo@4x.png'

import Header from './Header'
import Users from "./Users";

import UserDataGrid from './UserDataGrid'
import AdminView from "./AdminView";
import EmployeesView from "./EmployeesView";
import Evaluations from "./Evaluations"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MainView({ signOut }) {
  const [value, setValue] = React.useState(0);
  const [userGroup, setUserGroup] = useState('')

  useEffect(() => {
    getUserData();
    sendFeedbackRequest();
  }, []);


  const sendFeedbackRequest = async (userId) => {
    let patientInfo = {
      userId: "103189"
    }

    // const number = JSON.stringify(patientNumber)
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ'
      },
      body: JSON.stringify(patientInfo)
    };

    const response = await fetch(`https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getFeedbackByUser
    `, requestOptions);
    const json = await response.json()

    const patients = json.Item.lastUpdateTime
    console.log("response " + patients);
    //s return obj;
  }



  const getUserData = async () => {

    let groupNr = 0;

    let user = await Auth.currentAuthenticatedUser();
    groupNr = user.attributes.locale;
    setUserGroup(groupNr)

  };


  function ShowTabPanel() {
    switch (userGroup) {
      case '1':
        return (
          <View>
            <TabPanel value={value} index={0}>
              <UserDataGrid groupNr={'2C'} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <UserDataGrid groupNr={'2D'} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <UserDataGrid groupNr={'2E'} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <UserDataGrid groupNr={'1B'} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <UserDataGrid groupNr={'TEST'} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <AdminView />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <EmployeesView />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <Evaluations />
            </TabPanel>
          </View>
        )
      case '3':
        return (
          <View>
            <TabPanel value={value} index={0}>
              <UserDataGrid groupNr={'Gronn'} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AdminView />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <EmployeesView />
            </TabPanel>
          </View>
        )
      case '1B':
        return (
          <View>
            <TabPanel value={value} index={0}>
              <UserDataGrid groupNr={'1B'} />
            </TabPanel>
          </View>
        )
      case '2C':
        return (
          <View>
            <TabPanel value={value} index={0}>
              <UserDataGrid groupNr={'2C'} />
            </TabPanel>
          </View>
        )
      case '2D':
        return (
          <View>
            <TabPanel value={value} index={0}>
              <UserDataGrid groupNr={'2D'} />
            </TabPanel>
          </View>
        )
      case 'Gronn':
        return (
          <View>
            <TabPanel value={value} index={0}>
              <UserDataGrid groupNr={'Gronn'} />
            </TabPanel>
          </View>
        )
      case '2E':
        return (
          <View>
            <TabPanel value={value} index={0}>
              <UserDataGrid groupNr={'2E'} />
            </TabPanel>
          </View>
        )
      default:
        return null
    }
  }


  function ShowTab() {

    switch (userGroup) {
      case '1':
        return (
          <Tabs
            textColor='secondary'
            TabIndicatorProps={{ style: { background: '#b1ebf4' } }}
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="2C" {...a11yProps(0)} />
            <Tab label="2D" {...a11yProps(1)} />
            <Tab label="2E" {...a11yProps(2)} />
            <Tab label="1B" {...a11yProps(3)} />
            <Tab label="TEST" {...a11yProps(4)} />
            <Tab label="Pasienter" {...a11yProps(5)} />
            <Tab label="Ansatte" {...a11yProps(6)} />
            <Tab label="Evaluering" {...a11yProps(7)} />
          </Tabs>
        )
      case '3':
        return (
          <Tabs
            textColor="secondary"
            TabIndicatorProps={{ style: { background: '#b1ebf4' } }}
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="Grønn" {...a11yProps(0)} />
            <Tab label="Pasienter" {...a11yProps(1)} />
            <Tab label="Ansatte" {...a11yProps(2)} />
          </Tabs>
        )
      case 'Gronn':
        return (
          <Tabs
            textColor="secondary"
            TabIndicatorProps={{ style: { background: '#b1ebf4' } }}
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="Grønn" {...a11yProps(0)} />
          </Tabs>
        )
      case '1B':
        return (
          <Tabs
            textColor="secondary"
            TabIndicatorProps={{ style: { background: '#b1ebf4' } }}
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="1B" {...a11yProps(0)} />
          </Tabs>
        )
      case '2C':
        return (
          <Tabs
            textColor="secondary"
            TabIndicatorProps={{ style: { background: '#b1ebf4' } }}
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="2C" {...a11yProps(0)} />
          </Tabs>
        )
      case '2D':
        return (
          <Tabs
            textColor="secondary"
            TabTextProps={{ style: { background: 'white' } }}
            TabIndicatorProps={{ style: { background: '#b1ebf4' } }}
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="2D" {...a11yProps(0)} />
          </Tabs>
        )
      case '2E':
        return (
          <Tabs
            textColor="secondary"
            TabIndicatorProps={{ style: { background: '#b1ebf4' } }}
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab label="2E" {...a11yProps(0)} />
          </Tabs>
        )
      default:
        return null
    }
  }



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexDirection: 'row', flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 800 }}>
      <Box sx={{ bgcolor: '#51B7CE', height: '100%' }}>
        <Box sx={{ height: 100, width: '100%', paddingTop: '15%', marginBottom: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <img style={{ width: '32%', height: '70%' }} src={logo} alt="Logo" />
        </Box>
        <ShowTab />
      </Box>
      <Box sx={{ flexDirection: 'row', width: '100%' }}>
        <Header signOut={signOut} />
        <ShowTabPanel />
      </Box>
    </Box>
  );
}