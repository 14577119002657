import React, { Component } from "react";

export const sort = (data) => {
    let hikeArray = [0, 0, 0, 0, 0]
    let cycleArray = [0, 0, 0, 0, 0]
    let screenArray = [0, 0, 0, 0, 0]
    let gymArray = [0, 0, 0, 0, 0]
    let poolArray = [0, 0, 0, 0, 0]
    let horseArray = [0, 0, 0, 0, 0]
    let canoeArray = [0, 0, 0, 0, 0]
    let playOutsideArray = [0, 0, 0, 0, 0]
    let musicMoveArray = [0, 0, 0, 0, 0]
    let outdoorArray = [0, 0, 0, 0, 0]
    let climbingArray = [0, 0, 0, 0, 0]
    
    for(let i = 0; i < data.length; i++){
        switch (data[i].hike){
            case 1: 
            hikeArray[0] = hikeArray[0] + 1
            break; 
            case 2: 
            hikeArray[1] = hikeArray[1] + 1
            break;
            case 3: 
            hikeArray[2] = hikeArray[2] + 1
            break;
            case 4: 
            hikeArray[3] = hikeArray[3] + 1
            break;
            case 5: 
            hikeArray[4] = hikeArray[4] + 1
            break;
        } 
        switch (data[i].cycle){
            case 1: 
            cycleArray[0] = cycleArray[0] + 1
            break; 
            case 2: 
            cycleArray[1] = cycleArray[1] + 1
            break;
            case 3: 
            cycleArray[2] = cycleArray[2] + 1
            break;
            case 4: 
            cycleArray[3] = cycleArray[3] + 1
            break;
            case 5: 
            cycleArray[4] = cycleArray[4] + 1
            break;
        } 
        switch (data[i].screen){
            case 1: 
            screenArray[0] = screenArray[0] + 1
            break; 
            case 2: 
            screenArray[1] = screenArray[1] + 1
            break;
            case 3: 
            screenArray[2] = screenArray[2] + 1
            break;
            case 4: 
            screenArray[3] = screenArray[3] + 1
            break;
            case 5: 
            screenArray[4] = screenArray[4] + 1
            break;
        } 
        switch (data[i].horse){
            case 1: 
            horseArray[0] = horseArray[0] + 1
            break; 
            case 2: 
            horseArray[1] = horseArray[1] + 1
            break;
            case 3: 
            horseArray[2] = horseArray[2] + 1
            break;
            case 4: 
            horseArray[3] = horseArray[3] + 1
            break;
            case 5: 
            horseArray[4] = horseArray[4] + 1
            break;
        } 
        switch (data[i].pool){
            case 1: 
            poolArray[0] = poolArray[0] + 1
            break; 
            case 2: 
            poolArray[1] = poolArray[1] + 1
            break;
            case 3: 
            poolArray[2] = poolArray[2] + 1
            break;
            case 4: 
            poolArray[3] = poolArray[3] + 1
            break;
            case 5: 
            poolArray[4] = poolArray[4] + 1
            break;
        } 
        switch (data[i].canoe){
            case 1: 
            canoeArray[0] = canoeArray[0] + 1
            break; 
            case 2: 
            canoeArray[1] = canoeArray[1] + 1
            break;
            case 3: 
            canoeArray[2] = canoeArray[2] + 1
            break;
            case 4: 
            canoeArray[3] = canoeArray[3] + 1
            break;
            case 5: 
            canoeArray[4] = canoeArray[4] + 1
            break;
        } 
        switch (data[i].gym){
            case 1: 
            gymArray[0] = gymArray[0] + 1
            break; 
            case 2: 
            gymArray[1] = gymArray[1] + 1
            break;
            case 3: 
            gymArray[2] = gymArray[2] + 1
            break;
            case 4: 
            gymArray[3] = gymArray[3] + 1
            break;
            case 5: 
            gymArray[4] = gymArray[4] + 1
            break;
        } 
        switch (data[i].climbing){
            case 1: 
            climbingArray[0] = climbingArray[0] + 1
            break; 
            case 2: 
            climbingArray[1] = climbingArray[1] + 1
            break;
            case 3: 
            climbingArray[2] = climbingArray[2] + 1
            break;
            case 4: 
            climbingArray[3] = climbingArray[3] + 1
            break;
            case 5: 
            climbingArray[4] = climbingArray[4] + 1
            break;
        } 
        switch (data[i].outdoor){
            case 1: 
            outdoorArray[0] = outdoorArray[0] + 1
            break; 
            case 2: 
            outdoorArray[1] = outdoorArray[1] + 1
            break;
            case 3: 
            outdoorArray[2] = outdoorArray[2] + 1
            break;
            case 4: 
            outdoorArray[3] = outdoorArray[3] + 1
            break;
            case 5: 
            outdoorArray[4] = outdoorArray[4] + 1
            break;
        } 
        switch (data[i].musicMove){
            case 1: 
            musicMoveArray[0] = musicMoveArray[0] + 1
            break; 
            case 2: 
            musicMoveArray[1] = musicMoveArray[1] + 1
            break;
            case 3: 
            musicMoveArray[2] = musicMoveArray[2] + 1
            break;
            case 4: 
            musicMoveArray[3] = musicMoveArray[3] + 1
            break;
            case 5: 
            musicMoveArray[4] = musicMoveArray[4] + 1
            break;
        } 
        switch (data[i].playOutside){
            case 1: 
            playOutsideArray[0] = playOutsideArray[0] + 1
            break; 
            case 2: 
            playOutsideArray[1] = playOutsideArray[1] + 1
            break;
            case 3: 
            playOutsideArray[2] = playOutsideArray[2] + 1
            break;
            case 4: 
            playOutsideArray[3] =playOutsideArray[3] + 1
            break;
            case 5: 
            playOutsideArray[4] =playOutsideArray[4] + 1
            break;
        } 
    }
    return [poolArray, gymArray, hikeArray,  horseArray, canoeArray, climbingArray, cycleArray, outdoorArray, screenArray, playOutsideArray, musicMoveArray ]; 
}