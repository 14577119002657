import React from "react";
import {useNavigate} from "react-router-dom";

import Adfectus_logo from "../assets/Adfectus_logo.png";

import MOCK_DATA from '../assets/MOCK_GROUP_DATA.json';

function Settings() {

    let navigate = useNavigate();

    return (

    <div className="MainLogin">

        <img src={Adfectus_logo} className="Logo" alt="Logo" />
    
    </div>

    );
}
export default Settings;