import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Flex,
  Text,
  SwitchField
} from "@aws-amplify/ui-react";


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';



import { DataGrid } from '@mui/x-data-grid';

import Typography from '@mui/material/Typography';
import MaterialButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Container from '@mui/material/Container';

import {post, getUsers, postEmployee} from "../services/UserService";
import React, {useMemo, useState, useEffect}  from "react";
import { Auth } from "aws-amplify";

const columns = [
  { field: 'userId', headerName: 'PasientID', width: 200 },
  {
    field: 'firstName',
    headerName: 'Fornavn',
    width: 220,
    editable: false,
  },

  {
    field: 'arrivalDate',
    headerName: 'Ankomst',
    type: 'dateTime',
    width: 200,
    editable: false,
    valueGetter: (params) => {
        if (params.value == null){
            return '';
        }
        const valueFormatted = formatDate(params.value)
        return `${valueFormatted}`;
    }
  },
  {
    field: 'groupId',
    headerName: 'Gruppe',
    width: 200,
    editable: false,
  },

];



function Admin({ }) {

  const [inputValue, setInputValue] = useState()

  let currentDate = new Date();
  let startDate = new Date(currentDate.getFullYear(), 0, 1);
  var days = Math.floor((currentDate - startDate) /
      (24 * 60 * 60 * 1000));
        
  var weekNumber = Math.ceil(days / 7);

  const [open, setOpen] = React.useState(false);
  const [pNR, setpNr] = useState(100)
  const [name, setName] = useState("")

  const [value, setValue] = useState(0);

  const [userData, setUserData] = useState([])
  const [shownData, setShownData] = useState([])
  const [isChecked, setIsChecked] = useState(true);
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [group, setGroup] = React.useState('Gronn');
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [isNew, setIsNew] = useState(true)
  const [userInst, setUserInst] = useState('')

  const selectHandleChange = (event) => {
    setGroup(event.target.value);
  };


  const pNRHandleChange = (event) => {
   setpNr(event.target.value);
  };
  const nameHandleChange = (event) => {
  setName(event.target.value);
  };

  const handleClickOpen = () => {

if(!isNew){
  setName("")
  setpNr(100)
  setGroup('')
 setArrivalDate(new Date())
 setIsNew(true)
} else {
  setName("")
  setpNr(100)
}

    setDialogTitle("Ny bruker")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  const handleSave = () => {
    setOpen(false); 

    let patient_object = null
   
    if(userInst === '02'){
    patient_object= {
      userId: "RKHR" + pNR,
      groupId: "Gronn", 
      firstName: name, 
      arrivalDate: arrivalDate, 
      instId: userInst
    }}
    else if (userInst === '01'){
      patient_object= {
        userId: pNR,
        groupId: group, 
        firstName: name, 
        arrivalDate: arrivalDate, 
        instId: userInst
      }

    }


console.log("DAte " + pNR)

post(patient_object)
  }
 
   useEffect(() => {

    getInst()

    
}, []); 


const getUserData = async (inst) => {

  let instInfo = {
    instId: inst
  }

  const requestOptions = {
    method: 'POST',
    headers: { 
        'Content-Type': 'application/json',
        'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ', 
    }, 
    body: JSON.stringify(instInfo)
};
fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getAllUsers', requestOptions)
.then(response => response.json())
.then(function(data) {
setUserData(data.Items)

setShownData(data.Items)
},);
}

const getInst = async () => {

let user = await Auth.currentAuthenticatedUser();

setUserInst(user.attributes.zoneinfo)
getUserData(user.attributes.zoneinfo)
}


const handleChange = (event, newValue) => {
  setValue(newValue);
  
  switch(newValue){
      case 0: filterPreviousUsers()
      break; 
      case 1: filterCurrentUsers()
      break; 
      case 2: filterFutureUsers()

  }
};

const handleRowClick = (e) => {
 console.log(e.row.firstName); 

 setDialogTitle("Rediger bruker")
 setOpen(true);
 setName(e.row.firstName)
 setpNr(e.row.userId)
 setGroup(e.row.groupId)
setArrivalDate(new Date(e.row.arrivalDate))

setIsNew(false)
}



 const filterFutureUsers = () => {

   setShownData(userData.filter(value => new Date(value.arrivalDate).getTime() > new Date(currentDate).getTime()))
 }

 const filterCurrentUsers = () => {

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

let firstSort = userData.filter(value => new Date(value.arrivalDate).getTime() <= new Date(currentDate).getTime())

setShownData(firstSort.filter(value => new Date(value.arrivalDate).addDays(20).getTime() > new Date(currentDate).getTime()))
 }

const filterPreviousUsers = () => {

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  setShownData(userData.filter(value => new Date(value.arrivalDate).addDays(20).getTime() < new Date(currentDate).getTime()))
}

function ShowGroup(){
  switch(userInst){
    case '01': 
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">  
      <InputLabel id="demo-simple-select-label">Gruppe</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={group}
        label="Gruppe"
        onChange={selectHandleChange}
      >
        <MenuItem value={"2C"}>2C</MenuItem>
        <MenuItem value={"2D"}>2D</MenuItem>
        <MenuItem value={"2E"}>2E</MenuItem>
        <MenuItem value={"1B"}>1B</MenuItem>
        <MenuItem value={"TEST"}>TEST</MenuItem>
      </Select>
    </FormControl>
    )
    break;
    case '02': 
    return null 
    break;
  }
}

function ShowSeason(){
  switch(userInst){
    case '01': 
    return (
      <View>
<Typography
sx={{ mt: 4 }} variant="subtitle2">Sesong</Typography>
<FormControlLabel control={<Switch defaultChecked />} label="Vinter" />
</View>
    )
    break; 
    case '02': 
    return null
  }
}

 const allUsers = () => {
  setShownData(userData)
}

    return (
        <View className="App">
          <Flex  direction="column">
        <Box   
       sx={{ width: '20%', mx: 3, top: '22%', right: '0%', position: 'absolute', }}>
        <MaterialButton  
        color="custom"
      variant="contained" onClick={handleClickOpen}>
        Opprett ny bruker
      </MaterialButton>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Legg inn nødvendige pasientopplysninger. Alle felt er obligatoriske.
          </DialogContentText>
          <TextField
          sx={{ my: 2 }}
            autoFocus
            margin="dense"
            id="patientNumber"
            label="Pasientnummer"
            fullWidth
            variant="standard"
            value={pNR}
            onChange={pNRHandleChange}
            required
          />
          <TextField
          sx={{ my: 2 }}
            autoFocus
            margin="dense"
            id="patientName"
            label="Fornavn"
            fullWidth
            variant="standard"
            value={name}
            onChange={nameHandleChange}
            required
          />
     <ShowGroup />     
<Typography sx={{ mt: 2 }} variant="subtitle2">Ankomstdato</Typography>
<DatePicker selected={arrivalDate} onChange={(date) => setArrivalDate(date)} />
<ShowSeason />
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={handleClose}>Avbryt</MaterialButton>
          <MaterialButton onClick={handleSave}>Lagre</MaterialButton>
        </DialogActions>
      </Dialog>

           <Container>
           <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Tidligere" />
        <Tab label="Nåværende" />
        <Tab label="Kommende" />
      </Tabs>
    </Container>
    <Box sx={{ height: 630, width: '100%', paddingTop: 6 }}>
      <DataGrid
        getRowId={(row) => row.userId}
        onRowClick={handleRowClick}
        rows={shownData}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
          </Flex>
        
        </View>
      );
}

export default withAuthenticator(Admin);


const formatDate = (date) => {


  var newDate = new Date(date)
  
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  
  let format = day + "." + month + "." + year
  
  return format;
  
  }