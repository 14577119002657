import React, {useMemo}  from "react";
import {useNavigate} from "react-router-dom";
import {post} from "../services/UserService"

import {
    Button,
    Flex,
    Heading,
    Text,
    TextField,
    View,
    withAuthenticator,
  } from "@aws-amplify/ui-react";



function NewUser () {

let navigate = useNavigate();

 const saveUser = (event) => {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      userId: form.get("id"),
      groupId: form.get("group"), 
      weekNumber: form.get("week")
    };

    post(data)
 }  

    return (
        <View className="App">
          <Heading level={1}>My Notes App</Heading>
          <View as="form" margin="3rem 0" onSubmit={saveUser}>
            <Flex direction="row" justifyContent="center">
              <TextField
                name="id"
                placeholder="Pasientnummer"
                label="Pasientnummer"
                labelHidden
                variation="quiet"
                required
              />
              <TextField
                name="week"
                placeholder="Ukenummer"
                label="Ukenummer"
                labelHidden
                variation="quiet"
                required
              />
                <TextField
                name="group"
                placeholder="Gruppe"
                label="Gruppe"
                labelHidden
                variation="quiet"
                required
              />


              <Button type="submit" variation="primary">
                Opprett
              </Button>
            </Flex>
          </View>
          
        </View>
      );

}

export default NewUser;


