import React, { Component } from "react";
import  Chart from "react-apexcharts";
import { sort } from  '../services/SortingService'
import { AiTwotonePieChart } from "react-icons/ai";

function ColumnChart(props) {


let options = { 
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10
      },
    },
    xaxis: {
      type: 'string',
      categories: ['Basseng', 'Aktivitet gymsal', 'Gå tur', 'Hest', 'Utendørs vann', 
       'Klatring', 'Sykling', 'Friluftsliv',  'Aktivitet skjerm',  'Leke ute', 'Bevegelse musikk'
         
      ],
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    fill: {
      opacity: 1
    }
}
        let series = [{ 
            name: 'Ikke gøy',
            data: [props.data[0][0], props.data[1][0], props.data[2][0], props.data[3][0], props.data[4][0], props.data[5][0], props.data[6][0], props.data[7][0], props.data[8][0], props.data[9][0], props.data[10][0]]
          }, {
            name: 'Skummelt',
            data: [props.data[0][1], props.data[1][1], props.data[2][1], props.data[3][1], props.data[4][1], props.data[5][1], props.data[6][1], props.data[7][1], props.data[8][1], props.data[9][1], props.data[10][1]]
          }, {
            name: 'Litt gøy',
            //data: [this.state.poolArray[2], this.state.gymArray[2], this.state.hikeArray[2], this.state.horseArray[2], this.state.canoeArray[2], this.state.climbingArray[2], this.state.cyclingArray[2], this.state.outdoorArray[2], this.state.screenArray[2], this.state.playOutsideArray[2], this.state.musicMoveArray[2]]
            data: [props.data[0][2], props.data[1][2], props.data[2][2], props.data[3][2], props.data[4][2], props.data[5][2], props.data[6][2], props.data[7][2], props.data[8][2], props.data[9][2], props.data[10][2]]
          },
          {
            name: 'Veldig gøy',
            data: [props.data[0][3], props.data[1][3], props.data[2][3], props.data[3][3], props.data[4][3], props.data[5][3], props.data[6][3], props.data[7][3], props.data[8][3], props.data[9][3], props.data[10][3]]
          }, 
          {
            name: 'Vet ikke',
            data: [props.data[0][4], props.data[1][4], props.data[2][4], props.data[3][4], props.data[4][4], props.data[5][4], props.data[6][4], props.data[7][4], props.data[8][4], props.data[9][4], props.data[10][4]]
          }]

      return (
        

  <div id="chart">
<Chart options={options} series={series} type="bar" height={450} width= {1150} />
</div>


      );
    }

export default ColumnChart;