export const COLUMNS = [
    {
        Header: 'Bruker Id',
        Footer: 'Bruker Id',
        accessor: 'user_id'
    }, 
    {
        Header: 'Aktivitet',
        Footer: 'Aktivitet',
        accessor: 'activity'
    },
    {
        Header: 'Svar',
        Footer: 'Svar',
        accessor: 'score'
    }
]
