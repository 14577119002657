import React, {useMemo}  from "react";
import {useNavigate} from "react-router-dom";
import { useTable, useSortBy } from "react-table";

import Sidebar from "./Sidebar";

import MOCK_DATA from '../assets/MOCK_GROUP_DATA.json';
import {COLUMNS} from './GroupsColumns';

import {AiFillCaretDown, AiFillCaretUp} from "react-icons/ai";

import "./Groups.css";

function Groups() {

    let navigate = useNavigate();

    const columns = useMemo(() => COLUMNS, [])

    const data = useMemo(() => MOCK_DATA, [])

    const tableInstance = useTable({columns, data})

    const {
        getTableProps, 
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
    useSortBy
    )
    

    return (

    <div className="Main">
    
        <Sidebar/>


        <h1>Grupper </h1>

        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}> 
                            {column.render('Header')}
                            <span>
                                {column.isSorted ? (column.isSortedDesc ? <AiFillCaretDown/> : <AiFillCaretUp/>): ' '}
                            </span>
                        </th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()} onClick={() => navigate(`/groupinfo/${cell.row.values.group}${cell.row.values.week}${cell.row.values.year}`)}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                    })}  
            </tbody>
            <tfoot>
                {
                    footerGroups.map(footerGroup => (
                        <tr {...footerGroup.getFooterGroupProps()}>
                            {footerGroup.headers.map(column => (
                                <td {...column.getFooterProps}>{column.render('Footer')}</td>
                                ))}
                        </tr>
                    ))
                }
            </tfoot>   
        </table>
    

    </div>

    );
}
export default Groups;