import React from 'react';
import { node } from 'prop-types';




export const post = async(user) =>{


    const postUser = JSON.stringify(user)

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            'x-api-key': 'k3z6bi0JJj73fPtTkXcTkI4QVJf4mFf7UbalzTp5'
        },
        body: postUser
    };
    fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/userHandlerAdmin', requestOptions)
        .then(response => console.log(response.status))
}

export const postEmployee = async(user, requestMethod = 'POST') =>{

    const postUser = JSON.stringify(user)

    const requestOptions = {
        method: requestMethod,
        headers: { 
            'Content-Type': 'application/json',
            'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ'
        },
        body: postUser
    };
    fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/handleUserPool', requestOptions)
        .then(response => console.log(response.status))
}


export const getUsers = async => {
let items = null

    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            'x-api-key': 'hspdRarV5q7l445hkChKS9B87MDaR9PraFHgbtFZ', 
        }
    };
   fetch('https://exh919iy8g.execute-api.eu-north-1.amazonaws.com/api/getAllUsers', requestOptions)
    .then(function(response) {
        return response.json();
      }).then(function(data) {
        console.log(data.Items); 
        items = data.Items; 
      });
      return items
    }