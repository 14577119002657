import React,  { useState, useEffect }  from "react"

import {useNavigate, Link, Navigate} from "react-router-dom";

import { Auth } from 'aws-amplify';


import Sidebar from "./Sidebar";

import "./Mainmeny.css";
import Groupinfo from "./Groupinfo";

function Mainmeny({authorized, setFilter}) {
    
let user; 

    async function getUserInfo() {
        user = await Auth.currentAuthenticatedUser();
        console.log('attributes:', user.attributes.email);
        navigate('/users', {email: user.attributes.email})
        
      }


    let navigate = useNavigate();


    const handleClickUsers = () => {
        getUserInfo()
       
    }

    authorized = true;

    if (!authorized) {
        return <Navigate replace to="/" />;
    }

    return (

    <div>

    <div className="MainPage">
        <h1>Velkommen!</h1>

        <div className="Rolle">
            <p>Logget inn som: </p>
        </div>
            
        <div className="ButtonGroup">
           {/*  <button onClick={() => navigate('/groups')}> Grupper</button> */}
            <button onClick={() => handleClickUsers()}> Brukere</button>
        </div>

      {/*   <input type="submit" value="Logg ut" className="Logout" onClick={() => navigate('/')}/>
 */}
    </div>

    </div>
     

    );
}

export default Mainmeny;