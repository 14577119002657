import React from 'react'
import ReactDom from 'react-dom';

const PopupMain = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '50px',
  zIndex: 1000
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000
}

export default function AddUserPopUp({open, children, onClose}) {
  if (!open) return null
  
    return ReactDom.createPortal(
    <>
        <div style={OVERLAY_STYLES}/>
        <div style={PopupMain}> 
        <form>
          <label for="firstname">Fornavn</label><br/>
          <input type="text" name="firstname" /><br/>
          <label for="lastname">Etternavn</label><br/>
          <input type="text" name="lastname" /><br/>
          <input type="submit" value="Submit" />
          <button onClick={onClose}>Lukk</button>
   
        </form>
        </div>
    </>,
    document.getElementById('portal')
  )
}
