import React from "react";
import {useNavigate} from "react-router-dom";

import "./Sidebar.css";


function Sidebar() {

    let navigate = useNavigate();

    return (

    <div className="Sidebar">
        <button className="DashboardHeader" onClick={() => navigate('/mainmeny')}> Dashboard </button>
        <button className="SidebarButton" onClick={() => navigate('/groups')}> Grupper </button>
        <button className="SidebarButton" onClick={() => navigate('/users')}> Brukere </button>
       {/*  <button className="SidebarInnstillinger" onClick={() => navigate('/settings')}> Innstillinger </button> */}
        <button className="SidebarInnstillinger" onClick={() => navigate('/')}> Logg ut</button> 
    </div>

    );
}
export default Sidebar;